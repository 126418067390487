import { useLocation } from '@reach/router'
import { useAuthContext, useUserContext } from '@tawal/lan-logic'
import { Spinner } from '@tawal/ui'
import { redirectAfterLogIn } from '@tawal/utils'
import { useEffect } from 'react'

import { Login } from '@/views'

export default () => {
  const { isAuthenticated } = useAuthContext()
  const { isAutoLoggingIn } = useUserContext()
  const location = useLocation()

  const isRedirectToApp = isAutoLoggingIn && isAuthenticated

  useEffect(() => {
    if (isRedirectToApp) {
      redirectAfterLogIn(location)
    }
  }, [isRedirectToApp, location])

  return isAutoLoggingIn || isAuthenticated ? <Spinner size="large" /> : <Login />
}
